import React, { useState, useRef, useEffect } from 'react'
import "./scss/welcome.scss";

function LoadingScreen() {
    const Ref = useRef(null);  
    const [timer, setTimer] = useState('');

    const startTimer = (e) => {
        if(e >= 0){
            setTimer(e);
        }
    }

    const clearTimer = (e) => {  
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e--);
        }, 1000);
        Ref.current = id;
    }
  
    useEffect(() => {
        clearTimer('4');
    }, []);

    return (
        <div className="welcome">
            <div className="welcome-wrapper">
                <div className="welcome-content">
                    <div><img src={require('./imgs/loading.gif')} /></div>
                    <div>
                        <h2>COOL SHIT LOADING...</h2>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingScreen;