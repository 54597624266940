import React, { useEffect, useRef } from "react";
import "./scss/about-us.scss";
import { withRouter, useHistory } from "react-router-dom";
import { motion as m } from "framer-motion";

function AboutUs({ trigger, modal }) {
    const showHideRef = useRef();

    const closeMe = (e) =>{
        showHideRef.current.classList.add('hide');
        modal(false);
    }

    useEffect(() => {
        if (trigger) {
            showHideRef.current.classList.remove('hide');
            trigger=false;
        }else{
            closeMe();
        }
      });

    return (
        <div className="about-us hide" ref={showHideRef} >
            <div className="about-us-container">
                <div className="about-us-wrapper">
                    <div className="about-us-header">
                        <div className="toilet-roll">
                            <img src={require('./imgs/toilet-roll@2x.png')} alt="Toilet roll" />
                        </div>
                        <a className="btn-close" onClick={closeMe.bind(this)} >
                            <img src={require('./imgs/close.png')} alt="Close" />
                        </a>
                    </div>
                    <div className="about-us-content-wrapper">
                        <div className="about-us-content">
                            <div className="about-us-content__title">
                                <h2>ABOUT</h2>
                            </div>
                            <div className="about-us-content__desc">
                                Don’t let toilet time go to waste. Load up on some inspiration instead. Who knows when some of this crap might come in handy?
                            </div>
                        </div>
                        <div className="about-us-content">
                            <div className="about-us-content__title submit">
                                <h2>SUBMIT</h2>
                            </div>
                            <div className="about-us-content__desc">
                            If you know a site that’s full of cool shit, 
                            <br/><a href="https://forms.gle/T3Swo63DECeXdEKTA" target="_blank">share it with us</a>.
                            </div>
                        </div>
                    </div>
                    <div className="about-us-footer">
                        <div className="light-bulb">
                            <img src={require('./imgs/light-bulb@2x.png')} alt="" />
                        </div>
                        <div className="ddb-logo">
                            <img src={require('./imgs/ddb@2x.png')} alt="DDB" />
                        </div>
                        <div className="tagline">
                            SPARK SOME STINKING GOOD IDEAS
                        </div>
                    </div>
                    <div className="curve">
                        <img src={require('./imgs/curve-line@2x.png')} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AboutUs;