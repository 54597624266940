import React, { useState, useEffect, useRef } from "react";
import "./scss/feed-page.scss";
import "./scss/fixed-bar.scss";
import "./scss/loader.scss";
import "./scss/tooltip.scss";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import axios, * as others from "axios";
import LoadingScreen from "./welcome";
import AboutUs from "./about-us";
import apiConfig from "./config.json";
import { motion as m } from "framer-motion";

function FeedPage() {
  const [initialized, setInitialized] = useState(false);
  const [feed, setFeed] = useState([]);
  const [loading, setLoading] = useState(true);
  const [trigger, setModal] = useState(false);
  const feedContainerRef = useRef();
  const loaderRef = useRef();
  const loaderWrapperRef = useRef();
  const btnMoreRef = useRef();
  const tooltipRef = useRef();
  const cookies = new Cookies();
  const corsUrl = apiConfig.rss_api_url + "?rss_url=";
  const apiKey = "&api_key=" + apiConfig.rss_api_key;
  const urls = require('./feeds.json');
  var coolShitCookie = [];
  var usedUrlsList = urls;
  if (cookies.get("cool_shit_cookie")) {
    coolShitCookie = cookies.get("cool_shit_cookie");
    coolShitCookie = coolShitCookie.split(',');
  }

  const getSingleFeed = async () => {
    let feedsCount = 0;
    let getList = [];
    let feed = {};

    do {
      let index = Math.floor(Math.random() * (urls.length - 1));
      let url = usedUrlsList[index];
      let feeds = [];
      // console.log(usedUrlsList);
      if (url) {
        let response = {};
        try {
          let finalURL = `${corsUrl}${url}${apiKey}`;
          response = await axios.get(finalURL);
          if(feeds){
            feeds = response.data.items;
          }
        } catch (ex) {
          console.log(ex);
        }

        let newFeeds = [];
        //check through single Site first
        feeds.forEach((feed, idx, arr) => {
          if (!feedExist(feed.guid)) {
            newFeeds.push(feed);
          }
        });
        feedsCount = newFeeds.length;

        //remove the site
        if (feedsCount == 0) {
          usedUrlsList.splice(index, 1);
        } else {
          getList = newFeeds;
        }
      } else {
        feedsCount = -1;
      }
    } while (feedsCount == 0);

    if (feedsCount == -1) {
      removeCookie("cool_shit_cookie");
      window.location.reload();
    }

    //Get feed
    if (getList) {
      let i = Math.floor(Math.random() * (getList.length - 1));
      feed = getList[i];
    }
    return feed;    
  };

  const getFeed = () => {
    let newFeed = getSingleFeed();
    newFeed.then((feed) => {
      setFeed(feed);
      setCookie(feed.guid);
    })
      .then(() => {
        if(document.querySelector(".loader")){
          loader(false);          
        }        
      });
  }

  const clickMore = (e) => {
    e.target.classList.add("hide");
    loader(true);
    getFeed();
  };

  const setCookie = (url) => {
    let d = new Date();
    d.setTime(d.getTime() + (60 * 60 * 24 * 1000 * 1));
    coolShitCookie.push(url);
    cookies.set('cool_shit_cookie', coolShitCookie.join(','), { path: '/', expires: d});
    // console.log(coolShitCookie);
  };

  const removeCookie = (name) => {
    cookies.remove(name, { path: '/' });
  }

  const feedExist = (url) => {
    let isExist = false;
    if (coolShitCookie && coolShitCookie.includes(url)) {
      isExist = true;
    }
    return isExist;
  }

  const loader = (active) => {
    if(loaderRef.current){
      if (active) {
        loaderRef.current.classList.add('active');
        setTimeout(() => {
          loaderWrapperRef.current.classList.add('active');          
        }, 1000);
      } else {
        setTimeout(() => {
          if(loaderRef.current.classList.contains('active')){
            loaderRef.current.classList.remove('active');
            loaderWrapperRef.current.classList.remove('active');
          }
        }, 2000);
      }
    }   
  }

  const scrollToTop = () =>{
    if(feedContainerRef.current){
      feedContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    } 
  }

  const enableMoreBtn = () =>{
   setTimeout(() => {
    if(btnMoreRef.current && btnMoreRef.current.classList.contains("hide")){
      btnMoreRef.current.classList.remove("hide");
    }
   }, 2000);
  }

  const welcomeScreen = (countDown) =>{
    setTimeout(() => {
      setLoading(false);
    }, countDown);
  }

  const shareThis = (e) =>{
    e.preventDefault();
    let url = tooltipRef.current.dataset.url;
    navigator.clipboard.writeText(url);

    console.log( tooltipRef.current.classList);
    tooltipRef.current.classList.add("active");
    setTimeout(() => {
      tooltipRef.current.classList.remove("active");
    }, 3000);
  }

  useEffect(() => {
    if (!initialized) {
      //Show welcome screen
      welcomeScreen(4000);
      getFeed();
      setInitialized(true);
    }
    scrollToTop(); 
    enableMoreBtn();   
  });
  return (
    <>
      {loading === false ? (
        <div className="feed-wrapper">
          <div className="loader" ref={loaderRef}>
            <div className="loader-wrapper" ref={loaderWrapperRef}>              
              <div className="loader__img"> 
                <img src={require('./imgs/loading.gif')} />
              </div>
              <div className="loader__title">
                  <h2>COOL SHIT LOADING...</h2>
              </div>
            </div>
          </div>
          <div className="feed-page" ref={feedContainerRef}>
            <div className="feed-page__title">
              <h1>{feed.title}</h1>
            </div>
            <div className="feed-page__thumbnail">
              <img src={feed.thumbnail} />
            </div>
            <div className="feed-page__desc">
              <div dangerouslySetInnerHTML={{ __html: feed.content }} />
            </div>
            <div className="feed-page__cta">
              <a href={feed.link} target="_blank">Source</a>
            </div>
          </div>
          <div className="fixed-bar">
            <div className="left-wrapper">
              <a className="btn-share" onClick={shareThis.bind(this)}>
                <img className="" src={require('./imgs/share@2x.png')} alt="Share" />
                <span className="share-tooltip" ref={tooltipRef} data-url={feed.link}>copied</span>
              </a>
              <a className="btn-about" onClick={ () => setModal(true) }>
                <img className="" src={require('./imgs/about@2x.png')} alt="More Please" />
              </a>
            </div>
            <div className="right-wrapper">              
              <a className="btn-more" onClick={clickMore.bind(this)} ref={btnMoreRef}>
                <img className="" src={require('./imgs/btn-more@2x.png')} alt="More Please" />
              </a>
            </div>
          </div>

          <AboutUs trigger={trigger} modal={setModal} />
        </div>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}
export default withRouter(FeedPage);

