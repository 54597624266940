import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { createBrowserHistory as createHistory } from "history";
import FeedPage from "./feed-page";
import { AnimatePresence } from 'framer-motion';

const history = createHistory();
const Animated = () => {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Switch location={location}
        key={location.pathname}>
        <Route
          path="/"
          exact
          component={props => <FeedPage {...props} />}
        />
      </Switch>
    </AnimatePresence>
  )
}

function App() {
  return (
    <div className="App">
      <>
        <Router>
          <Animated />
        </Router>
      </>
    </div>
  );
}
export default App;